
import {
  Component,
  Mixins,
  Prop,
  Ref,
} from 'vue-property-decorator';
import { ValidateMixin } from '@/mixins';
import {
  Table,
  Checkbox,
  Radio,
} from '@/components';
import { VForm } from '@/types';
import { clone_deep } from '@/utils';
import { Attractions } from '../../types';

@Component({
  components: {
    Table,
    Checkbox,
    Radio,
  },
})
export default class FieldsAttractionForm extends Mixins(ValidateMixin) {
  @Ref()
  readonly form!: VForm;

  @Prop()
  fields!: Attractions.TimeslotTableCols[];

  get fields_model() {
    return clone_deep(this.fields);
  }

  form_valid = false;

  headers = [
    {
      text: '',
      value: 'actions',
    },
    {
      text: 'Название колонки',
      value: 'text',
    },
    {
      text: 'Отображение',
      value: 'view',
    },
  ]

  submit() {
    if (this.form.validate()) {
      this.$emit('submit', this.fields_model);
    }
  }
}
