
import {
  Component, Mixins, Watch, Prop, Ref,
} from 'vue-property-decorator';
import { VForm } from '@/types';
import {
  TextField,
  Select,
} from '@/components';
import { attractions_groups, attraction_types } from '@/enums';
import { worktables_store } from '@/store';
import { WorktableSharedType } from '@/modules/worktables/shared/types';
import { ErrorHandlereMixin, ValidateMixin } from '@/mixins';
import { Attractions } from '../../types';

@Component({
  components: {
    TextField,
    Select,
  },
})
export default class BaseAttractionDataForm extends Mixins(
  ValidateMixin,
  ErrorHandlereMixin,
) {
  @Ref()
  readonly form!: VForm;

  @Prop()
  item!: Attractions.Attraction;

  async created() {
    try {
      if (!worktables_store.state.worktables.length) {
        await worktables_store.actions.get_worktables();
      }
    } catch (error) {
      this.handleServerError(error);
    }
  }

  form_valid = false;

  get attraction() {
    return this.item;
  }

  get attraction_groups_list() {
    return attractions_groups;
  }

  get worktables(): WorktableSharedType[] {
    return worktables_store.state.worktables;
  }

  get attraction_types() {
    return attraction_types;
  }

  @Watch('attraction', { immediate: true, deep: true })
  validate_form() {
    if (this.form) {
      this.form.validate();
    }
  }

  submit() {
    if (this.form.validate()) {
      this.$emit('submit', this.attraction);
    }
  }

  back_route() {
    this.$router.push({
      name: Attractions.Pages.ATTRACTIONS,
    });
  }
}
